import { useLogin } from './useLogin';

interface ShopifyOAuthInfo {
    hmac?: string;
    host?: string;
    session?: string;
    shop: string;
    timestamp?: string;
}

const baseUrl = ENV.OAUTH_URL;
const OAUTH_INFOS_KEY = 'OAUTH_INFOS';
const { getToken } = useLogin();

function setOAuthInfos(infos: ShopifyOAuthInfo) {
    localStorage.setItem(OAUTH_INFOS_KEY, JSON.stringify(infos));
}

function getOAuthInfos(): ShopifyOAuthInfo | undefined {
    const infos = localStorage.getItem(OAUTH_INFOS_KEY);
    if (!infos) return undefined;
    return JSON.parse(infos);
}

function clearOAuthInfos() {
    localStorage.removeItem(OAUTH_INFOS_KEY);
}

export function useShopifyOAuth() {
    const checkOAuthRequest = async () => {
        const queryParams = new URLSearchParams(window.location.search);
        console.log('params', queryParams)
        const savedOAuthInfos = getOAuthInfos();
        const shop = queryParams.get('shop') ?? savedOAuthInfos?.shop;
        const hmac = queryParams.get('hmac') ?? savedOAuthInfos?.hmac;
        const host = queryParams.get('host') ?? savedOAuthInfos?.host;
        const session = queryParams.get('session') ?? savedOAuthInfos?.session;
        const timestamp = queryParams.get('timestamp') ?? savedOAuthInfos?.timestamp;

        if (!shop || typeof shop !== 'string') return;
        // const token = getToken();
        // if (!token) {
        //     setOAuthInfos({
        //         hmac,
        //         host,
        //         session,
        //         shop,
        //         timestamp,
        //     });
        //     return;
        // }

        const queryParamsString = `?hmac=${hmac}&host=${host}&session=${session}&shop=${shop}&timestamp=${timestamp}`; // &jwt=${token}`;
        // clearOAuthInfos();
        window.location.href = baseUrl + queryParamsString;
        await new Promise(resolve => setTimeout(resolve, 5000)); //prevent app from showing if we are redirecting
    };
    return {
        checkOAuthRequest,
    };
}
